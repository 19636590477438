import React from 'react';
import './index.css';
//import {auth} from './myFirebase'
//import { useAuthContext } from './context/AuthContext';

import { Link } from 'react-router-dom';

function Header() {
//    const { user } = useAuthContext();

//    const logout = ()=>{
//        auth.signOut().then(()=>{
//            alert("ログアウトしました");
//        })
//    }

    return(
        <header className="text-gray-300 body-font bg-indigo-500">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <div className="flex title-font font-medium items-center text-white">
                    <h1><Link className="ml-3 text-2xl" to="/">クオサーチ！｜ギフト券がもらえる無料キャンペーン検索サイト</Link></h1>
                </div>
                {/*
                <nav className="ml-auto flex flex-wrap items-center text-base justify-center hidden">
                    {(user.isAnonymous)&&(
                            <Link className="hover:text-white text-white" to="/signup">ログイン</Link>
                    )
                    }
                    {(!user.isAnonymous)&&(
                            <Link className="hover:text-white text-white" to="/" onClick={logout}>ログアウト</Link>
                    )
                    }
                </nav>
                */}
            
            </div>
            {/* <img className="w-full" srcSet={`${process.env.PUBLIC_URL}/logo_sm.png 640w`} src={`${process.env.PUBLIC_URL}/logo.png`} alt="header"/> */}
            <picture>
                <source media="(min-width: 640px)" srcSet={`${process.env.PUBLIC_URL}/logo.png`} width="2200" height="500"/>
                <source type="image/webp" srcSet={`${process.env.PUBLIC_URL}/logo_sm.webp`} alt="header"  width="640" height="200" />
                <img className="w-full" src={`${process.env.PUBLIC_URL}/logo_sm.png`} alt="header" width="640" height="200" />
            </picture>
         </header>
         
    );
}

export default Header;
