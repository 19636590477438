import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter} from 'react-router-dom';
import Header from './Header';

//import reportWebVitals from './reportWebVitals';
//import App from './App';

import { AuthProvider } from './context/AuthContext';
import { ServiceDataProvider } from './context/ServiceDataContext';


const App = React.lazy(() => import('./App'));


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <Header />
        <Suspense fallback={
                <div className="container py-20 mx-auto">
                  <div className="w-full h-screen z-50 overflow-hidden flex flex-col items-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                    <h2 className="text-center   text-xl font-semibold">Loading...</h2>
                  </div>
                </div>
                }>
          <ServiceDataProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ServiceDataProvider>
        </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
