import { createContext, useState, useContext, useEffect } from 'react';

const ServiceDataContext = createContext();

export function useServiceDataContext() {
  return useContext(ServiceDataContext);
}

export function ServiceDataProvider({ children }) {
  const [serviceData, setServiceData] = useState();

  const value = {
    serviceData,
    setServiceData
  };

/*  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => {
      unsubscribed();
    };
  }, []);
*/
  return <ServiceDataContext.Provider value={value}>{children}</ServiceDataContext.Provider>;
}